<template>
  <loader :size="64" />
</template>

<script>
export default {
  created() {
    const code = this.$route.query?.code;
    if (code) {
      return api
        .post('/auth/checkcode', { code })
        .finally(() => window.close());
    }
    return this.$router.push({ name: 'index' });
  },
};
</script>

<style lang="scss" scoped>
.loader {
  height: 100%;
  align-items: center;
}
</style>
